import React, { useState } from "react"
import { graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import { HashLoader } from "react-spinners"
const backend = require("../helpers/devsurvival.api.js")

const contactSuccess = "Contact Request Sent!"
export default ({ data, location }) => {
  const { title: siteTitle } = data.site.siteMetadata
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
    notification: "",
    loading: false,
  })

  const sendEmail = async e => {
    e.preventDefault()
    // validating that the input fields is not empty
    if (
      Object.values(formState)
        .slice(0, 3)
        .some(v => v === "")
    ) {
      setFormState({
        ...formState,
        notification: "please fill out all missing fields",
      })
      return
    }
    setFormState({ ...formState, notification: "", loading: true })
    try {
      await backend.sendContactRequest(formState)
      setFormState({
        name: "",
        email: "",
        message: "",
        notification: contactSuccess,
        loading: false,
      })
    } catch (err) {
      setFormState({
        name: "",
        email: "",
        message: "",
        notification: "failed to send email, please try again later",
        loading: false,
      })
    }
  }
  const onChange = e =>
    setFormState({ ...formState, [e.target.name]: e.target.value })
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact" />
      {formState.notification === contactSuccess ? (
        <h4>{contactSuccess}</h4>
      ) : (
        <div>
          {formState.loading ? (
            <HashLoader loading={formState.loading} size={60} />
          ) : (
            <form className="flex-column-start" onSubmit={sendEmail}>
              <h4>Contact</h4>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={formState.name}
                onChange={onChange}
                placeholder="Name*"
                required
              />
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={formState.email}
                onChange={onChange}
                placeholder="Email*"
                required
              />
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                value={formState.message}
                onChange={onChange}
                placeholder="Message*"
                required
              />
              <input type="submit" value="Send" />
              <p>
                {formState.notification.length ? formState.notification : ""}
              </p>
            </form>
          )}
        </div>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
